<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="10">
          <h1 class="text-h6" v-text="page_title" />
        </v-col>
        <v-col cols="12" xs="12" md="2" class="text-right">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="secondary"
            size="small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.view'}`,
              params: { id: id },
            }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form v-on:submit.prevent ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12" xs="12" md="6">
            <v-col cols="12" xs="12" md="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'GENERAL'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-text-field
                    label="Nombre*"
                    v-model="data.name"
                    :rules="rules.name"
                    type="text"
                    @keyup.enter="handleSubmit"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>
          <v-col cols="12" xs="12" md="6">
            <v-col cols="12" xs="12" md="12" v-if="store_mode">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'ARCHIVOS'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <FileInputImg
                    label="Fotografía"
                    :model.sync="data.photo_file"
                    :rules="rules.photo_file"
                    accept="image/jpeg"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>
          <v-col cols="12" xs="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
//import functions
import { storeUpdate, show } from "../../requests/pageRequest";
import { msgConfirm, msgAlert } from "../../control";
//import components
import BtnCircle from "../../components/BtnCircle.vue";
import FileInputImg from "../../components/FileInputImg.vue";

export default {
  components: {
    BtnCircle,
    FileInputImg,
  },
  data() {
    return {
      //route name and page title
      page_route: "color",
      page_title: "Color",
      //vars control
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login_info: this.$store.getters.getLogin,
      loading: false,
      //data for api
      data: {
        name: "",
        photo_file: null,
      },
      //rules form data
      rules: {
        name: [
          (v) => (!v ? "Campo requerido" : true),
          (v) => (v && v.length < 2 ? "Mínimo 2 caracteres" : true),
          (v) => (v && v.length > 100 ? "Máximo 100 caracteres" : true),
        ],
        photo_file: [
          (v) => (v && v.size > 2e6 ? "El archivo debe ser menor a 2MB" : true),
        ],
      },
    };
  },
  mounted() {
    //compare store or update
    if (!this.store_mode) {
      this.loading = true;
      //get data for ID
      show(this.page_route, this.login_info.token, this.id).then((response) => {
        this.data = response;
        this.loading = false;
      });
    }
  },
  methods: {
    handleSubmit() {
      //validate form data
      if (this.$refs.form_data.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.store_mode ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;
              //store or update action
              storeUpdate(
                this.page_route,
                this.login_info.token,
                this.id,
                this.data,
                this.login_info.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.$router.push({
                      name: `${this.page_route}${
                        this.store_mode ? "" : ".view"
                      }`,
                      params: { id: this.id },
                    })
                  : console.log(response.message);
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
};
</script>